<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is- title">
                {{ $t('Rates per room') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mini-gap is-mobi">
          <div class="column is-12">
            <Filter
              class="per-room-filter"
              column-size="is-narrow"
              :steps="steps"
              @clear-filter="resetList"
              @filter="fetchData"
            />
          </div>
        </div>
        <a-table
          v-if="isRenderTable"
          row-key="name"
          :data-source="rows"
          :loading="isFetching"
          :locale="tableLocale"
          :pagination="false"
          :scroll="{ x: scrollWidth }"
          class="price-table"
        >
          <a-table-column
            v-if="Object.keys(dates).length > 0"
            key="name"
            data-index="name"
            :width="180"
            :fixed="nameColFixed"
          >
            <template #title>
              <span>{{ $t('Price') }}</span>
            </template>
          </a-table-column>

          <a-table-column
            v-for="date in Object.keys(dates).sort((a, b) => a > b ? 1 : -1)"
            :key="date"
            :data-index="date"
            :width="120"
            align="right"
          >
            <template #title>
              {{ $filters.date(date, { format: 'MM/DD'}) }}
            </template>
            <template #default="{ record }">
              <a-input-number
                v-if="form.rates[date] != null && record.editable === true"
                v-model:value="form.rates[date].price"
                :formatter="v => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="v => v.replace(/(,*)/g, '')"
                class="price-input has-text-right"
                size="large"
                :min="0"
                :controls="false"
                :disabled="date < today"
              />
              <span v-else>
                {{ record.rates[date]?.unit }}
                {{ $filters.number(record.rates[date]?.price) }}
              </span>
            </template>
          </a-table-column>
        </a-table>
        <div class="columns is-mini-gap is-mobi is-multiline">
          <div class="column has-text-right is-12 m-t-4x">
            <a-button
              v-permission="['export-rate']"
              :disabled="!params"
              :size="size"
              class="button-secondary-outline rate-save-btn"
              @click="handleExportCSV"
            >
              {{ $t('Export to CSV') }}
            </a-button>
            <Modal>
              <template #default="{ hide }">
                <SubmitExternalConfirm
                  :rates="generateData()"
                  :vendor="vendor"
                  @confirmed="() => {
                    handleConfirm();
                    hide();
                  }"
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <slot name="handler" :show="show">
                  <a-button
                    v-permission="['modify-rate']"
                    :disabled="!params"
                    :size="size"
                    class="rate-save-btn"
                    type="primary"
                    @click.prevent="show"
                  >
                    {{ $t('Submit to External') }}
                  </a-button>
                  <a-button
                    v-permission="['modify-rate']"
                    :disabled="!params"
                    :size="size"
                    class="rate-save-btn"
                    type="primary"
                    @click="handleSave"
                  >
                    {{ $t('Save') }}
                  </a-button>
                </slot>
              </template>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Rates per room": "Rates per room",
    "no data": "Please select vendor, property, room, and plan name",
    "Submit to External": "Submit to External",
    "savedData.success": "Save successful"
  },
  "ja": {
    "Rates per room": "１部屋あたりの料金",
    "no data": "Vendor, 物件、部屋、プラン名を選択してください",
    "Submit to External": "外部に送信",
    "savedData.success": "Save successful"
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import debounce from 'lodash/debounce';
import Modal from '@/components/Modal';
import Filter from './components/Filter';
import SubmitExternalConfirm from './components/SubmitExternalConfirm';

export default {
  name: 'RatesPerRoom',
  components: {
    Modal,
    Filter,
    SubmitExternalConfirm,
  },
  data() {
    return {
      size: 'large',
      nameColFixed: false,
      isFetching: false,
      tableLocale: {
        emptyText: this.$t('no data'),
      },
      form: {
        rates: {},
      },
      rates: {
        external: {},
        piop: {},
        registered: {},
      },
      data: {
        external: [],
        piop: [],
        registered: [],
      },
      today: dayjs(new Date()).format('YYYY-MM-DD'),
      dates: {},
      isRenderTable: true,
      scrollWidth: 1300,
      steps: 10,
      calculationBasePax: 0,
      params: undefined,
      vendors: undefined,
      vendor: undefined,
    };
  },
  computed: {
    rows() {
      const { external, piop, registered } = this.rates;
      const vendor = this.vendors?.find((v) => v.code === this.params?.vendor);
      return [
        {
          name: vendor?.name,
          rates: external || {},
        },
        {
          name: this.$t('PIOP Price'),
          rates: piop || {},
        },
        {
          name: this.$t('Registering Price'),
          rates: registered || {},
          editable: true,
        },
      ];
    },
  },
  async mounted() {
    const table = document.querySelector('.price-table .ant-table-body');

    if (table) {
      table.addEventListener('scroll', this.debounceHandleScroll.bind(this));
    }

    this.setResponsiveColumns();

    window.addEventListener('resize', () => {
      this.setResponsiveColumns();
    });
  },
  updated() {
    const table = document.querySelector('.price-table .ant-table-body');

    if (table) {
      const { scrollWidth, clientWidth } = table;
      const maxScroll = scrollWidth - clientWidth;

      table.scrollLeft = Math.floor(maxScroll / 5);
    }
  },
  unmounted() {
    const table = document.querySelector('.price-table .ant-table-body');

    if (table) {
      table.removeEventListener('scroll', this.debounceHandleScroll.bind(this));
    }
  },
  async created() {
    // this.params = this.buildParamsFromQueries();
    await this.fetchData();
  },
  methods: {
    debounceHandleScroll: debounce(async function handler(e) {
      const maxScroll = e.target.scrollWidth - e.target.clientWidth;

      if (e.target.scrollLeft >= maxScroll) {
        return this.fetchNextDates();
      }

      if (e.target.scrollLeft <= 0) {
        return this.fetchPreviousDates();
      }

      return false;
    }, 600),

    buildParamsFromQueries() {
      const {
        vendor,
        propertyId: hotelNo,
        roomCode,
        plan: planId,
        dateFrom: fromDate,
        dateTo: toDate,
      } = this.$route.query;

      if (vendor && hotelNo && roomCode && planId && fromDate && toDate) {
        return {
          vendor, hotelNo, roomCode, planId, availablePeriod: { fromDate, toDate },
        };
      }

      return null;
    },
    async fetchData() {
      const params = this.buildParamsFromQueries();
      if (!params) return;
      this.vendors = await this.$store.dispatch('iscm-price-auto-reflection/listExternalVendors');
      this.vendor = this.vendors?.find((v) => v.code === params.vendor);
      this.dates = {};
      const { fromDate, toDate } = params.availablePeriod;
      this.getDates(dayjs(fromDate), dayjs(toDate))
        .forEach((date) => { this.dates[date] = { price: 0, unit: 'JPY' }; });
      this.params = params;

      try {
        await this.$store.dispatch(
          'iscm-properties/listRoom',
          {
            ...params,
            type: 'PlanRateConditionPerRoom',
          },
        );
      } catch (error) {
        this.$message.error(error);
        this.$router.replace({ query: null });
        this.resetList();
      }

      this.form.rates = {};
      await this.fetchRegisteredRates(Object.keys(this.dates));
      await this.fetchExternalData(Object.keys(this.dates));
      await this.fetchPiopData(Object.keys(this.dates));
    },
    async fetchExternalData(dates = null) {
      this.isFetching = true;
      try {
        const { rateConditionPerRoom } = await this.$store.dispatch(
          'iscm-external/ratePerRoom', { ...this.params, dates },
        );
        this.data.external = this.data.external.concat(rateConditionPerRoom);

        this.rates.external = {
          ...this.dates,
          ...this.rates.external,
          ...this.parsePrice(rateConditionPerRoom),
        };
      } catch (error) {
        this.rates.external = { ...this.dates };
      }

      this.isFetching = false;
    },
    async fetchPiopData(dates = null) {
      this.isFetching = true;
      try {
        const { rateConditionPerRoom } = await this.$store.dispatch(
          'iscm-piop/ratePerRoom', { ...this.params, dates },
        );
        this.data.piop = this.data.piop.concat(rateConditionPerRoom);
        this.rates.piop = {
          ...this.dates,
          ...this.rates.piop,
          ...this.parsePrice(rateConditionPerRoom),
        };
        this.applyPiopRateToRegistered();
      } catch (error) {
        this.rates.piop = { ...this.dates };
      }

      this.isFetching = false;
    },
    async fetchRegisteredRates(dates = []) {
      this.isFetching = true;
      let data = {};
      try {
        data = await this.$store.dispatch(
          'iscm-savedData/ratePerRoom', { ...this.params, dates },
        );
      } finally {
        const { rateConditionPerRoom } = data;
        this.data.registered = this.data.registered.concat(rateConditionPerRoom || []);
        this.form.rates = {
          ...{},
          ...this.dates,
          ...this.form.rates,
          ...this.parsePrice(rateConditionPerRoom || []),
        };
        this.isFetching = false;
      }
    },
    applyPiopRateToRegistered() {
      Object.entries(this.rates.piop).forEach(([date, value]) => {
        if (this.form.rates[date]?.price <= 0) {
          this.form.rates[date] = { ...value };
        }
      });
    },
    async fetchNextDates() {
      if (!this.params) return;

      const { availablePeriod } = this.params;
      const { toDate } = availablePeriod;
      const from = toDate ? dayjs(toDate).add(1, 'day') : dayjs();
      const to = dayjs(from).add(this.steps, 'days');
      const dates = this.getDates(from, to);
      dates.forEach((date) => {
        this.dates[date] = { price: 0, unit: 'JPY' };
      });

      await this.fetchRegisteredRates(dates);
      await this.fetchExternalData(dates);
      await this.fetchPiopData(dates);

      const { query } = this.$route;
      const lastDate = Object.keys(this.dates).sort().pop();
      await this.$router.push({
        query: {
          ...query,
          dateTo: lastDate,
        },
      });
      this.params.availablePeriod.toDate = lastDate;
    },
    async fetchPreviousDates() {
      if (!this.params) return;

      const { availablePeriod } = this.params;
      const { fromDate } = availablePeriod;
      const to = fromDate ? dayjs(fromDate).subtract(1, 'day') : dayjs();
      const from = dayjs(to).subtract(this.steps, 'days');
      const dates = this.getDates(from, to);
      dates.forEach((date) => {
        this.dates[date] = { price: 0, unit: 'JPY' };
      });

      await this.fetchRegisteredRates(dates);
      await this.fetchExternalData(dates);
      await this.fetchPiopData(dates);

      const { query } = this.$route;
      const firstDate = Object.keys(this.dates).sort().shift();
      await this.$router.push({
        query: {
          ...query,
          dateFrom: firstDate,
        },
      });
      this.params.availablePeriod.fromDate = firstDate;
    },
    parsePrice(rates) {
      if (rates == null) return {};

      const parsedData = {};
      rates.forEach(({ availableDate, ratePerRoom }) => {
        const rate = ratePerRoom.find(({ rateKind }) => rateKind.toString() === '2') || {};
        const { rateValue: price, currencyCode: unit } = rate;
        parsedData[availableDate] = { price: Number(price) || 0, unit };
      });

      return parsedData;
    },
    getDates(from, to) {
      const dates = [];
      let currentDate = from;

      while (currentDate <= to) {
        dates.push(dayjs(currentDate).format('YYYY-MM-DD'));
        currentDate = dayjs(currentDate).add(1, 'day');
      }

      return dates;
    },
    generateData() {
      const rateConditionPerRoom = Object.entries(this.form.rates)
        .filter(([date]) => date >= this.today)
        .map(([date, rate]) => ({
          availableDate: date,
          ratePerRoom: [
            {
              rateKind: 2,
              rateValue: rate.price,
              currencyCode: rate.unit,
            },
          ],
        }));

      return { ...this.params, rateConditionPerRoom };
    },
    async handleConfirm() {
      this.form.rates = {
        ...JSON.parse(JSON.stringify(this.dates)),
        ...JSON.parse(JSON.stringify(this.rates.piop)),
      };
      const dates = Object.keys(this.dates);
      this.isFetching = true;
      try {
        await this.fetchExternalData(dates);
      } finally {
        this.isFetching = false;
      }
    },
    async handleSave() {
      try {
        const payload = this.generateData();
        const { success } = await this.$store.dispatch('iscm-savedData/saveRatePerRoom', payload);
        if (success) this.$message.success(this.$t('savedData.success'));
      } catch (error) {
        this.$message.error('Error save data');
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    handleExportCSV() {
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
      try {
        const dates = Object.keys(this.dates).sort();
        const rateNames = [
          `${this.vendor?.name.replace(/ /m, '')}Price`,
          'PiopPrice',
          'RegisteringPrice',
        ];
        const headRows = ['date'].concat(rateNames).flat();
        const rows = [headRows].concat(dates.map((date) => {
          const row = [dayjs(date).format('MM/DD/YYYY')];
          [this.rates.external[date], this.rates.piop[date], this.form.rates[date]].forEach((r) => {
            row.push(r.price);
          });
          return row;
        }));
        const csvContent = rows.map((row) => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        const fileName = [
          'rates_per_room',
          this.vendor?.name.replace(/ /m, ''),
          `h${this.params.hotelNo}`,
          `r${this.params.roomCode}`,
          `p${this.params.planId}`,
          dayjs(dates[0]).format('YYYYMMDD'),
          dayjs(dates.slice(-1)).format('YYYYMMDD'),
        ].join('_');

        saveAs(blob, `${fileName}.csv`);
      } catch (error) {
        this.$message.error(error || 'Could not able to export csv');
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    setResponsiveColumns() {
      this.isRenderTable = false;
      this.nameColFixed = false;

      const width = window.innerWidth;

      if (width > 480) {
        this.nameColFixed = 'left';
      }

      this.isRenderTable = true;
    },
    resetList() {
      this.rates = {
        external: {},
        piop: {},
        registered: {},
      };
      this.params = null;
      this.dates = {};
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rate-save-btn {
  margin-left: 0.8rem;
  margin-bottom: 0.8rem;
  min-width: 115px;
}

.per-room-filter::v-deep {
  .rate-filter-input {
    min-width: 150px;
  }
}

.price-input::v-deep {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  margin-right: -10px;
}

.price-table::v-deep {
  margin-top: 2rem;
  margin-bottom: 1rem;

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 16px;

    .ant-checkbox-wrapper {
      color: white;
    }
  }

  .ant-table-head > tr > th > .ant-checkbox + span {
    padding-right: 0;
  }
}

.price-input::v-deep {
  width: 100%;

  input {
    text-align: right;
  }

  .ant-input-number-handler-wrap {
    display: none!important;
  }
}
</style>
